import React from 'react'
import experience from '../../Assets/images/1-experience.webp'
import swap from '../../Assets/images/2-swap.webp'
import staking from '../../Assets/images/3-staking.webp'
import amm from '../../Assets/images/4-amm.webp'
import liquidity from '../../Assets/images/5-liquidity.webp'
import erc from '../../Assets/images/6-erc.webp'
import { useRefs } from '../../Pages/RefsContext'
const Features = () => {
    const { featuresRef } = useRefs();
    return (
        <>
            {/* Features Section Start */}
            <section ref={featuresRef} className="section-pt-50 section-pb-50">
                <div className="container">
                    <div data-aos="fade-up" data-aos-duration="1500" className="row justify-content-center">
                        <div className="col-xl-6 col-lg-7 col-md-10">
                            <div className="zm_section-title text-center mb-60">
                                <span className="zm_sub-title zm_animate-text">Features</span>
                                <h3 className="title">Features and Functionality</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center flex-wrap mt-2 mt-md-5">
                        <div data-aos="fade-up" data-aos-duration="3000" className="col-xl-4 col-lg-4 col-sm-6  mb-4">
                            <div className="zm_statistical-item">
                                <div className="zm_statistical-item-top">
                                    <h4 className="mb-4">Elevate Your Experience</h4>
                                </div>
                                <div className="zm_statistical-item-image">
                                    <img src={experience} width="160" height="160" alt="experience img" />
                                </div>
                                <div className="zm_statistical-item-bottom">
                                    <p>Rewarding Ecosystem Earn Zeb Tokens effortlessly through our mobile utility platform.
                                        Turn everyday interactions into rewards that have real-world value.</p>
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="3000" className="col-xl-4 col-lg-4 col-sm-6  mb-4">
                            <div className="zm_statistical-item">
                                <div className="zm_statistical-item-top">
                                    <h4 className="mb-4">Seamless Swapping</h4>
                                </div>
                                <div className="zm_statistical-item-image">
                                    <img src={swap} width="160" height="160" alt="swap img" />
                                </div>
                                <div className="zm_statistical-item-bottom">
                                    <p>Convert your Zeb Tokens into USDT with our dedicated decentralized swap platform.
                                        Experience maximum and minimum limits tailored for both small and large
                                        transactions.</p>
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="3000" className="col-xl-4 col-lg-4 col-sm-6  mb-4">
                            <div className="zm_statistical-item">
                                <div className="zm_statistical-item-top">
                                    <h4 className="mb-4">High-Yield Staking</h4>
                                </div>
                                <div className="zm_statistical-item-image">
                                    <img src={staking} width="160" height="160" alt="staking img" />
                                </div>
                                <div className="zm_statistical-item-bottom">
                                    <p>Lock in your Zeb Tokens in various staking pools to earn attractive yields. Benefit
                                        from no withdrawal fees and a nominal penalty for early unstaking.</p>
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="3000" className="col-xl-4 col-lg-4 col-sm-6  mb-4">
                            <div className="zm_statistical-item">
                                <div className="zm_statistical-item-top">
                                    <h4 className="mb-4">AMM</h4>
                                </div>
                                <div className="zm_statistical-item-image">
                                    <img src={amm} width="160" height="160" alt="amm img" />
                                </div>
                                <div className="zm_statistical-item-bottom">
                                    <p>An automated market maker (AMM) is a type of decentralized exchange (DEX) protocol
                                        that relies on a mathematical formula to price assets. Instead of using an order
                                        book like a traditional exchange, assets are priced according to a pricing
                                        algorithm.</p>
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="3000" className="col-xl-4 col-lg-4 col-sm-6  mb-4">
                            <div className="zm_statistical-item">
                                <div className="zm_statistical-item-top">
                                    <h4 className="mb-4">Liquidity Pools</h4>
                                </div>
                                <div className="zm_statistical-item-image">
                                    <img src={liquidity} width="160" height="160" alt="liquidity img" />
                                </div>
                                <div className="zm_statistical-item-bottom">
                                    <p>Liquidity providers (LPs) add funds to liquidity pools. You could think of a
                                        liquidity pool as a big pile of funds that traders can trade against.</p>
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="3000" className="col-xl-4 col-lg-4 col-sm-6  mb-4">
                            <div className="zm_statistical-item">
                                <div className="zm_statistical-item-top">
                                    <h4 className="mb-4">ERC-20 Standard</h4>
                                </div>
                                <div className="zm_statistical-item-image">
                                    <img src={erc} width="160" height="160" alt="erc img" />
                                </div>
                                <div className="zm_statistical-item-bottom">
                                    <p>The ERC-20 Standard Token streamlines cryptocurrency, offering a set of rules for
                                        tokens to follow. With its unified set of functionalities, it allows for seamless
                                        interaction between decentralized applications.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* Features Section End */}
        </>
    )
}

export default Features