import React from 'react';
import instagram from '../../Assets/images/icons/instagram.webp'
import twitter from '../../Assets/images/icons/twitter.webp'
import youtube from '../../Assets/images/icons/youtube.webp'
import medium from '../../Assets/images/icons/medium.webp'
import backtotop from '../../Assets/images/icons/top.webp'

const Footer = () => {
    const scrollToTop = (event) => {
        event.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <>
            {/* Footer Section Start */}
            <footer className='zm_footer'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="zm_footer-copyright-text">
                                <p>Copyright © 2023 - All Rights Reserved By <span>ZebMoney</span></p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="zm_footer-card text-center text-md-end">
                                <div className="zm_footer-social">
                                    <a href="https://www.instagram.com/zebmoneyofficial/" target="_blank" rel="noreferrer" title="Instagram"><img src={instagram} width="22" height="22" className='instagram' alt="instagram icon" /></a>
                                    <a href="https://twitter.com/Zeb_money" target="_blank" rel="noreferrer" title="Twitter"><img src={twitter} width="22" height="22" alt="twitter icon" /></a>
                                    <a href="https://www.youtube.com/channel/UC1Cxqn82bW9r0q5mzBCtAJQ" target="_blank" rel="noreferrer" title="Youtube"><img src={youtube} width="22" height="22" alt="youtube icon" /></a>
                                    <a href="https://medium.com/@zebmoney" target="_blank" rel="noreferrer" title="Medium"><img src={medium} width="22" height="22" alt="medium icon" /></a>
                                    <a href="#!" onClick={scrollToTop} target="_blank" className='backtotop' rel="noreferrer">
                                        <img src={backtotop} width="30" height="30" alt="backtotop icon" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Section End */}
        </>
    )
}

export default Footer