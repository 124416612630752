import React from 'react'
import banneranim from '../../Assets/images/banneranim.webp'
import scrolldownbg from '../../Assets/images/scrolldownbg.svg'
import { useRefs } from '../../Pages/RefsContext' 
const Banner = () => {
    const { homeRef } = useRefs();
    return (
        <>
            {/* Banner Section Start */}
            <section ref={homeRef} className="zm_banner zm_banner-bg">
                <div className="container custom-container">
                    <div className="row justify-content-between">
                        <div className="col-xxl-6 col-xl-5 col-lg-6 order-lg-1">
                            <div className="zm_banner-img text-center">
                                <div className="mx-auto w-fit-content d-inline">
                                    <img src={banneranim} width="520" height="875" alt="Banner images" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-0">
                            <div className="zm_banner-content">
                                <h2 data-aos="fade-up" data-aos-duration="3000" className="zm_sub-title">The Zeb Ecosystem</h2>
                                <h1 data-aos="fade-up" data-aos-duration="3000" className="title mb-5">ZebMoney</h1>
                                <p data-aos="fade-up" data-aos-duration="3000" className=" mb-2">Empowering a Decade, Trust &
                                    Innovation
                                    Unite</p>
                                <p data-aos="fade-up" data-aos-duration="3000">A Decade of Innovation and Trust Since
                                    its inception 10 years ago, ZebMoney has been a trailblazer in the IT industry,
                                    seamlessly blending technology with financial empowerment. With our mobile
                                    utility platform that serves over 1 million users, we have mastered the art of
                                    generating value through ad integrations. Now, we are taking a monumental leap
                                    forward with the launch of Zeb Token, designed to reward our community and
                                    redefine digital engagement. Experience the future with ZebMoney.</p>
                                <div data-aos="fade-up" data-aos-duration="3000" className="zm_button d-flex flex-wrap">
                                    <a href="http://zebmoney.org/" className="zm_border-button" target='blank'><span>Zeb Stake</span></a>
                                    <a href="http://swap.zebmoney.io/" className="zm_border-button" target='blank'><span>Zeb Swap</span></a>
                                    <a href="https://docs.zebmoney.io/" className="zm_border-button" target='blank'><span>White Paper</span></a>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="3000" className='animcirclemain'>
                                    <div className="animcircle">
                                        <img src={scrolldownbg} width="190" height="190" alt="animation sroll" />
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" version="1.1">
                                            <path id="textPath" d="M 0,75 a 75,75 0 1,1 0,1 z"></path>
                                            <text>
                                                <textPath href="#textPath">ZebMoney * ZebMoney *</textPath>
                                            </text>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Waves Container */}
                <svg className="waves" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                    <defs>
                        <path id="gentle-wave"
                            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className="parallax">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
                </svg>
                {/* Waves end */}
            </section>
            {/* Banner Section End */}
        </>
    )
}

export default Banner