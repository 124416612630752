import React from 'react'
import banneranim2 from '../../Assets/images/banneranim-2.webp'
import { useRefs } from '../../Pages/RefsContext'
const Services = () => {
    const { aboutRef } = useRefs();
    return (
        <>
            {/* Services Section Start */}
            <section ref={aboutRef} className="services-area zm_ecosystem-main section-pt-50 section-pb-50">
                <div className="container custom-container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-lg-1">
                            <div data-aos="fade-up" data-aos-duration="3000" className="zm_section-title text-start mb-4">
                                <div className="zm_section-title text-left mb-60">
                                    <span className="zm_sub-title zm_animate-text">About Us</span>
                                </div>
                                <h2 className="title">One Stop for Multiple Earning</h2>
                            </div>
                            <div className="services__wrapper">
                                <div className="zm_ecosystem-item">
                                    <div className="zm_ecosystem-content">
                                        <h3 data-aos="fade-up" data-aos-duration="3000" className="title">Global Financial</h3>
                                        <p data-aos="fade-up" data-aos-duration="3000">An open and global financial system built for the internet age is an alternative
                                            to a system that’s opaque, tightly controlled, and held together by decades-old
                                            infrastructure and processes.</p>
                                    </div>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="3000" className="zm_ecosystem-item">
                                    <div className="zm_ecosystem-content">
                                        <h3 className="title">ZebMoney Benefits</h3>
                                        <p>Zeb Money brings numerous benefits when compared to traditional financial
                                            services. Distributed ledger containing information about all the activities
                                            that have taken place on a blockchain network is shared by everyone.</p>
                                    </div>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="3000" className="zm_ecosystem-item">
                                    <div className="zm_ecosystem-content">
                                        <h3 className="title">Distributed Systems</h3>
                                        <p>Through the use of smart contracts and distributed systems, deploying a financial
                                            application or product becomes much less complex and secure.</p>
                                    </div>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="3000" className="zm_ecosystem-item">
                                    <div className="zm_ecosystem-content">
                                        <h3 className="title">Traditional Finance</h3>
                                        <p>Zeb Money offers a transparent, efficient, and secure alternative to traditional
                                            finance through blockchain technology and smart contracts. It also promotes
                                            global financial inclusion, making services accessible to everyone.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-0 text-center">
                            <div data-aos="fade-up" data-aos-duration="3000" className="zm_services-images">
                                <img src={banneranim2} width="500" height="500" alt="Services img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Services Section End */}
        </>
    )
}

export default Services