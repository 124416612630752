import React from 'react'
import logogif from '../../Assets/images/logoanim.gif'

const Ecosystem = () => {
    return (
        <>
            {/* Ecosystem Section Start */}
            <section className="zm_ecosystem-main section-pt-50 section-pb-50">
                <div className="container custom-container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 order-lg-0">
                            <div data-aos="fade-up" data-aos-duration="3000" className="zm_section-title text-start mb-65">
                                <h3 className="title">Empowering Users in a Decentralized Ecosystem</h3>
                            </div>
                            <div className="zm_ecosystem-item">
                                <div className="zm_ecosystem-content">
                                    <h4 data-aos="fade-up" data-aos-duration="3000" className="title">An ecosystem of financial applications that is built on top of
                                        blockchain networks.</h4>
                                    <p data-aos="fade-up" data-aos-duration="3000">An open-source, permissionless, and transparent financial service ecosystem that is
                                        available to everyone and operates without any central authority. The users would
                                        maintain full control over their assets and interact with this ecosystem.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 order-lg-1">
                            <div data-aos="fade-up" data-aos-duration="3000" className="zm_ecosystem-images">
                                <img src={logogif} width="320" height="320" alt="ZebMoney img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Ecosystem Section End */}
        </>
    )
}

export default Ecosystem