import React from 'react'
import polygon from '../../Assets/images/polygon.webp'

const Polygon = () => {
    return (
        <>
            {/* Polygon Section Start */}
            <section className="zm_polygonmain section-pt-50 section-pb-50">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-7 col-md-10">
                            <div data-aos="fade-up" data-aos-duration="3000" className="zm_section-title text-center">
                                <span className="zm_sub-title zm_animate-text">Polygon</span>
                                <h3 className="title">Integrated Blockchain</h3>
                                <img src={polygon} width="180" height="180" alt="polygon img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Polygon Section End */}
        </>
    )
}

export default Polygon