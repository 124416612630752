import React from 'react'
import { Outlet } from 'react-router'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import { RefsProvider } from './RefsContext'
const Layout = () => {
    return (
        <>
            <RefsProvider>
                <Header />
                {/* Main Area Start */}
                <main className="main--area">
                    <Outlet />
                </main>
                {/* Main Area End */}
                <Footer />
            </RefsProvider>
        </>
    )
}

export default Layout