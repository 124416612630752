import React from 'react'
import { SwapRate } from '../../config'

const Statistical = () => {

    const { buyPrice } = SwapRate()
    return (
        <>
            {/* Statistical Section Start */}
            <section className="zm_statistical section-pt-50 section-pb-50">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-7 col-md-10">
                            <div data-aos="fade-up" data-aos-duration="3000" className="zm_section-title text-center mb-60">
                                <span className="zm_sub-title zm_animate-text">Statistical</span>
                                <h3 className="title">Statistical Data</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center flex-wrap mt-2 mt-md-5">
                        <div data-aos="fade-up" data-aos-duration="3000" className="col-xl-4 col-lg-4 col-sm-6  mb-4">
                            <div className="zm_statistical-item h-auto">
                                <h4 className="mb-1">10B ZEB</h4>
                                <p className="my-0">Total Supply</p>
                            </div>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="3000" className="col-xl-4 col-lg-4 col-sm-6  mb-4">
                            <div className="zm_statistical-item h-auto">
                                <h4 className="mb-1">${1 / buyPrice * 10000000000}</h4>
                                <p className="my-0">Market Cap</p>
                            </div>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="3000" className="col-xl-4 col-lg-4 col-sm-6  mb-4">
                            <div className="zm_statistical-item h-auto">
                                <h4 className="mb-1">${1 / buyPrice}</h4>
                                <p className="my-0">Current price</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Statistical Section End */}
        </>
    )
}

export default Statistical