import React from 'react'
import Banner from '../../Components/Banner/Banner'
import Ecosystem from '../../Components/Ecosystem/Ecosystem'
import Faqs from '../../Components/Faqs/Faqs'
import Features from '../../Components/Features/Features'
import Polygon from '../../Components/Polygon/Polygon'
import Roadmap from '../../Components/Roadmap/Roadmap'
import Services from '../../Components/Services/Services'
import Statistical from '../../Components/Statistical/Statistical'
import Tokenomics from '../../Components/Tokenomics/Tokenomics'

const Home = () => {
    return (
        <>
            <Banner />
            <Services />
            <Features />
            <Polygon />
            <Tokenomics />
            <Ecosystem />
            <Statistical />
            <Roadmap />
            <Faqs />
        </>
    )
}

export default Home