import React from 'react'
import { useRefs } from '../../Pages/RefsContext'
const Roadmap = () => {
    const { roadmapRef } = useRefs();
    return (
        <>
            {/* Roadmap Section Start */}
            <section ref={roadmapRef} className="roadMap section-pt-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="zm_faq-content">
                                <div className="row justify-content-center">
                                    <div className="col-xl-6 col-lg-7 col-md-10">
                                        <div data-aos="fade-up" data-aos-duration="3000" className="zm_section-title text-center mb-60">
                                            <span className="zm_sub-title zm_animate-text">Roadmap</span>
                                            <h3 className="title">The Roadmap to Success</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="ps-timeline-sec">
                                    <div className="container">
                                        <ol className="ps-timeline">
                                            <li data-aos="fade-up" data-aos-duration="3000">
                                                <div className="img-handler-top">
                                                    2023
                                                </div>
                                                <div className="ps-bot">
                                                    <b>Quarter 3:</b>
                                                    <p>1. Decentralized Swap Platform</p>
                                                    <p>2. Staking Platform Release</p>
                                                    <p>3. Security Audit</p>
                                                </div>
                                                <span className="ps-sp-top">01</span>
                                            </li>
                                            <li data-aos="fade-up" data-aos-duration="3000">
                                                <div className="img-handler-bot">
                                                    2023
                                                </div>
                                                <div className="ps-top">
                                                    <b>Quarter 4:</b>
                                                    <p>1. Community Engagement</p>
                                                    <p>2. Reward Programs</p>
                                                </div>
                                                <span className="ps-sp-bot">02</span>
                                            </li>
                                            <li data-aos="fade-up" data-aos-duration="3000">
                                                <div className="img-handler-top">
                                                    2024
                                                </div>
                                                <div className="ps-bot">
                                                    <b>Quarter 1 & Quarter 2:</b>
                                                    <p>1. NFT Integration</p>
                                                    <p>2. Global Partnerships</p>
                                                </div>
                                                <span className="ps-sp-top">03</span>
                                            </li>
                                            <li data-aos="fade-up" data-aos-duration="3000">
                                                <div className="img-handler-bot">
                                                    2024
                                                </div>
                                                <div className="ps-top">
                                                    <b>Quarter 3 & Quarter 4:</b>
                                                    <p>1. Layer 2 Scaling</p>
                                                    <p>2. Cross-Chain Support</p>
                                                    <p>3. UX/UI Overhaul</p>
                                                </div>
                                                <span className="ps-sp-bot">04</span>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Roadmap Section End */}
        </>
    )
}

export default Roadmap