import React from 'react'
import tokenomics from '../../Assets/images/tokenomics.svg'
import { useRefs } from '../../Pages/RefsContext'
import { Link } from 'react-router-dom';
const Tokenomics = () => {
    const { tokenomicsRef } = useRefs();
    return (
        <>
            {/* Tokenomics Section Start */}
            <section ref={tokenomicsRef} className="zm_tokenomicsmain section-pt-50 section-pb-50">
                
                <div className="container custom-container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-7 col-md-10">
                            <div data-aos="fade-up" data-aos-duration="3000" className="zm_section-title text-center mb-60">
                                <span className="zm_sub-title zm_animate-text">Read our Tokenomics here</span>
                                <h3 className="title">Tokenomics</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div data-aos="fade-up" data-aos-duration="3000" className="zm_tokenomicsmain_images">
                                <img src={tokenomics} width="705" height="705" alt="tokenomics img" />
                            </div>
                        </div>
                        <div className="col-lg-5 ps-lg-5">
                            <h2>Token Details</h2>
                            <ul>
                                <li data-aos="fade-up" data-aos-duration="3000">Chain Network</li>
                                <li data-aos="fade-up" data-aos-duration="3000">Polygon</li>
                                <li data-aos="fade-up" data-aos-duration="3000">Token Name</li>
                                <li data-aos="fade-up" data-aos-duration="3000">Zeb Money</li>
                                <li data-aos="fade-up" data-aos-duration="3000">Token Ticker</li>
                                <li data-aos="fade-up" data-aos-duration="3000">ZEB</li>
                                <li data-aos="fade-up" data-aos-duration="3000">Smart Contract</li>
                                <li data-aos="fade-up" data-aos-duration="3000">
                                <Link to="https://polygonscan.com/token/0xe8b630986d88653e29622046929aD90C0e3FbdBd" target='blank'>
                                    0xe8b...FbdBd
                                    
                                </Link>
                                    </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Waves Container */}
                <svg className="waves" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                    <defs>
                        <path id="gentle-wave"
                            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className="parallax">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
                </svg>
                {/* Waves end */}
            </section>

            {/* Tokenomics Section End */}
        </>
    )
}

export default Tokenomics